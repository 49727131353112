/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swire-frontline-ui/app/styles.scss";
@import "~@angular/material/theming";

@include mat-core();

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=block");

$frontline-workplace-frontend-admin-portal-primary: mat-palette($mat-indigo);
$frontline-workplace-frontend-admin-portal-accent: mat-palette(
  $mat-pink,
  A200,
  A100,
  A400
);
$frontline-workplace-frontend-admin-portal-warn: mat-palette($mat-red);
$frontline-workplace-frontend-admin-portal-theme: mat-light-theme(
  (
    color: (
      primary: $frontline-workplace-frontend-admin-portal-primary,
      accent: $frontline-workplace-frontend-admin-portal-accent,
      warn: $frontline-workplace-frontend-admin-portal-warn,
    ),
  )
);
@include angular-material-theme(
  $frontline-workplace-frontend-admin-portal-theme
);

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
